@font-face {
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/AktivGrotesk_A_Bd.ttf');
}

@font-face {
  font-family: 'Aktiv Grotesk';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/AktivGrotesk_A_BdIt.ttf');
}

@font-face {
  font-family: 'Aktiv Grotesk';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/AktivGrotesk_A_It.ttf');
}

@font-face {
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/AktivGrotesk_A_Rg.ttf');
}

@font-face {
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/AktivGrotesk_A_Th.ttf');
}

@font-face {
  font-family: 'Aktiv Grotesk';
  font-style: italic;
  font-weight: 200;
  src: url('../assets/fonts/AktivGrotesk_A_ThIt.ttf');
}
